import React, { useState, useContext, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './Profile.css';
import Angi from '../../../assets/Images/social/angi.webp';
import Facebook from '../../../assets/Images/social/facebook.png';
import Nextdoor from '../../../assets/Images/social/nextdoor.webp';
import Yelp from '../../../assets/Images/social/yelp.webp';
import User from '../../../assets/Images/User.webp';
import Userback from '../../../assets/Images/back.webp';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { BsArrowRepeat } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import Form from 'react-bootstrap/Form';
import { LoginContext } from '../../../LoginContext';
import { updateuserprofile } from '../../../Api/dashboardApi';
import { SITE_URL } from '../../../Api/config';
import { changepassword } from '../../../Api/dashboardApi';
import 'react-notifications/lib/notifications.css';
import UserImageProfile from '../../../assets/Images/profile.jpg';
import '../../../assets/css/custom.css';

function Profile() {

    const { LoggedInData, login, logout } = useContext(LoginContext);
    const [userData, setUserData] = useState({
        'first_name': '',
        'last_name': '',
        'user_email': '',
        'password': '',
        'phone': '',
        'address': '',
        'city': '',
        'state': '',
        'zip': '',
        'specialInstructions': '',
    })

    const [email, setemail] = useState('');
    const [city, setCity] = useState('');
    const [image, setImage] = useState('');
    const [keap_id, setKeap_id] = useState('');

    useEffect(() => {
        if (LoggedInData != null) {
            setUserData(LoggedInData)
            setCity(LoggedInData.city);
            setKeap_id(LoggedInData.keap_id);
        }

    }, [LoggedInData]);

    console.log("image", image);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [validated, setValidated] = useState(false);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setUserData((prevUserData) => ({
            ...prevUserData,
            [name]: value
        }));
    };

    const states = [
        { name: "Alabama", cities: ["Birmingham", "Montgomery", "Mobile"], stateCode: "AL" },
        { name: "Alaska", cities: ["Anchorage", "Fairbanks", "Juneau"], stateCode: "AK" },
        { name: "Arizona", cities: ["Phoenix", "Tucson", "Mesa"], stateCode: "AZ" },
        { name: "Arkansas", cities: ["Little Rock", "Fort Smith", "Fayetteville"], stateCode: "AR" },
        { name: "California", cities: ["Los Angeles", "San Diego", "San Jose"], stateCode: "CA" },
        { name: "Colorado", cities: ["Denver", "Colorado Springs", "Aurora"], stateCode: "CO" },
        { name: "Connecticut", cities: ["Bridgeport", "New Haven", "Hartford"], stateCode: "CT" },
        { name: "Delaware", cities: ["Wilmington", "Dover", "Newark"], stateCode: "DE" },
        { name: "Florida", cities: ["Jacksonville", "Miami", "Tampa"], stateCode: "FL" },
        { name: "Georgia", cities: ["Atlanta", "Augusta", "Columbus"], stateCode: "GA" },
        { name: "Hawaii", cities: ["Honolulu", "Pearl City", "Hilo"], stateCode: "HI" },
        { name: "Idaho", cities: ["Boise", "Nampa", "Meridian"], stateCode: "ID" },
        { name: "Illinois", cities: ["Chicago", "Aurora", "Rockford"], stateCode: "IL" },
        { name: "Indiana", cities: ["Indianapolis", "Fort Wayne", "Evansville"], stateCode: "IN" },
        { name: "Iowa", cities: ["Des Moines", "Cedar Rapids", "Davenport"], stateCode: "IA" },
        { name: "Kansas", cities: ["Wichita", "Overland Park", "Kansas City"], stateCode: "KS" },
        { name: "Kentucky", cities: ["Louisville", "Lexington", "Bowling Green"], stateCode: "KY" },
        { name: "Louisiana", cities: ["New Orleans", "Baton Rouge", "Shreveport"], stateCode: "LA" },
        { name: "Maine", cities: ["Portland", "Lewiston", "Bangor"], stateCode: "ME" },
        { name: "Maryland", cities: ["Baltimore", "Frederick", "Rockville"], stateCode: "MD" },
        { name: "Massachusetts", cities: ["Boston", "Worcester", "Springfield"], stateCode: "MA" },
        { name: "Michigan", cities: ["Detroit", "Grand Rapids", "Warren"], stateCode: "MI" },
        { name: "Minnesota", cities: ["Minneapolis", "Saint Paul", "Rochester"], stateCode: "MN" },
        { name: "Mississippi", cities: ["Jackson", "Gulfport", "Southaven"], stateCode: "MS" },
        { name: "Missouri", cities: ["Kansas City", "Saint Louis", "Springfield"], stateCode: "MO" },
        { name: "Montana", cities: ["Billings", "Missoula", "Great Falls"], stateCode: "MT" },
        { name: "Nebraska", cities: ["Omaha", "Lincoln", "Bellevue"], stateCode: "NE" },
        { name: "Nevada", cities: ["Las Vegas", "Henderson", "Reno"], stateCode: "NV" },
        { name: "New Hampshire", cities: ["Manchester", "Nashua", "Concord"], stateCode: "NH" },
        { name: "New Jersey", cities: ["Newark", "Jersey City", "Paterson"], stateCode: "NJ" },
        { name: "New Mexico", cities: ["Albuquerque", "Las Cruces", "Rio Rancho"], stateCode: "NM" },
        { name: "New York", cities: ["New York", "Buffalo", "Rochester"], stateCode: "NY" },
        { name: "North Carolina", cities: ["Charlotte", "Raleigh", "Greensboro"], stateCode: "NC" },
        { name: "North Dakota", cities: ["Fargo", "Bismarck", "Grand Forks"], stateCode: "ND" },
        { name: "Ohio", cities: ["Columbus", "Cleveland", "Cincinnati"], stateCode: "OH" },
        { name: "Oklahoma", cities: ["Oklahoma City", "Tulsa", "Norman"], stateCode: "OK" },
        { name: "Oregon", cities: ["Portland", "Salem", "Eugene"], stateCode: "OR" },
    ];

    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');

    // const cities = selectedState ? states.find((state) => state.name === selectedState).cities : [];

    const handleSubmit = async (event) => {
        event.preventDefault();
    };
    const handleStateChange = (event) => {
        setSelectedState(event.target.value);
        // setUserDetails({ ...userDetails, state: event.target.value });
    };

    const handleCityChange = (event) => {
        setSelectedCity(event.target.value);
        // setUserDetails({ ...userDetails, city: event.target.value });
    };

    const handlefileChange = (event) => {
        const file = event.target.files[0];
        setImage(file);
    };

    const updateprofile = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('user_id', LoggedInData.id);
        formData.append('first_name', userData.first_name);
        formData.append('last_name', userData.last_name);
        formData.append('user_email', userData.user_email);
        formData.append('phone', userData.phone);
        formData.append('address', userData.address);
        formData.append('city', city);
        formData.append('state', selectedState);
        formData.append('zip', userData.zip);
        formData.append('special_instructions', userData.special_instructions);
        formData.append('image', image);
        formData.append('keap_id', keap_id);

        updateuserprofile(formData).then((res) => {
            console.log("res", res);
            if (res.code == '200') {
                if (res.data.status == '1') {
                    alert("Profile updated successfully");
                    handleClose();
                    const apiResponse = res.data.data;
                    console.log("apiResponse", apiResponse);
                    login(apiResponse);
                } else {
                    NotificationManager.error(res.data.message);
                    handleClose();
                }

            }
            else {
                alert("Something went wrong");
            }
        });





    }

    useEffect(() => {
        if (userData.state) {
            setSelectedState(userData.state);
            setSelectedCity(userData.city);
        }
    }, [userData]);



    const [oldpassword, setOldPassword] = useState(null);
    const [newpassword, setNewPassword] = useState(null);
    const [confirmpassword, setConfirmPassword] = useState(null);
    const [signUprequest, setSignUpRequest] = useState(0);


    const saveChangePassword = (e) => {
        e.preventDefault();

        console.log("oldpassword", oldpassword);
        if (oldpassword == null || newpassword == null || confirmpassword == null) {
            setSignUpRequest(signUprequest + 1);
            return;
        }

        const formData = new FormData();
        formData.append('user_id', LoggedInData.id);
        formData.append('oldpass', oldpassword);
        formData.append('newpassword', newpassword);

        changepassword(formData).then((res) => {
            console.log("res", res);
            if (res.code == '200') {
                if (res.data.status == '1') {
                    NotificationManager.success("Password updated successfully!");

                }
                else {
                    NotificationManager.error("Invalid old password!");
                }
            }
            else {
                alert("Something went wrong");
            }
            handleClose2();
        });

    }


    const getstatename = (statecode) => {
        const state = states.find((state) => state.stateCode === statecode || state.name === statecode);
        if (state) {
            return state.name;
        }
        else {
            return '';
        }
    }


    return (

        <>
            {/* Edit Profile Modal start here  */}
            <div>
                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit User Information</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='custum-modal-body'>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Group className="mb-4" controlId="validationCustom01">
                                        <Form.Label className='custom-lable'>First name</Form.Label>
                                        <Form.Control required type="name" value={userData.first_name} name="first_name" onChange={(e) => handleChange(e)} placeholder="John" className='custom-input' />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter your first name
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6'>
                                    <Form.Group className="mb-4" controlId="validationCustom02">
                                        <Form.Label className='custom-lable'>Last name</Form.Label>
                                        <Form.Control required type="name" value={userData.last_name} name="last_name" onChange={(e) => handleChange(e)} placeholder="Deo" className='custom-input' />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter your last name
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <Form.Group className="mb-4" controlId="validationCustom03">
                                        <Form.Label className='custom-lable'>Email address</Form.Label>
                                        <Form.Control required type="email" disabled value={userData.user_email} name="user_email" onChange={(e) => handleChange(e)} placeholder="" className='custom-input' />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter your email
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <Form.Group className="mb-4" controlId="validationCustom10">
                                        <Form.Label className='custom-lable'>Please choose image</Form.Label>
                                        <Form.Control required type="file" accept='image/*' name="user_image" onChange={(e) => handlefileChange(e)} placeholder="" />
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Group className="mb-4" controlId="validationCustom04">
                                        <Form.Label className='custom-lable'>Number</Form.Label>
                                        <Form.Control required type="number" value={userData.phone} name="phone" onChange={(e) => handleChange(e)} placeholder="457-456-6794" className='custom-input' />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter your number
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6'>
                                    <Form.Group className="mb-4" controlId="validationCustom05">
                                        <Form.Label className='custom-lable'>Street Address</Form.Label>
                                        <Form.Control required type="text" value={userData.address} name="address" onChange={(e) => handleChange(e)} placeholder="" className='custom-input' />
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Group className="mb-4" controlId="validationCustom08">
                                        <Form.Label className='custom-lable'>State</Form.Label>
                                        <Form.Select
                                            aria-label="Default select example"
                                            className="custom-select-box"
                                            onChange={(e) => {
                                                console.log(e.target.value);
                                                handleStateChange(e);
                                            }}
                                        >
                                            <option value="">Select State</option>
                                            {states.map((state) => (
                                                <option value={state.stateCode} selected={state.stateCode === userData.state}> {state.name} </option>

                                            ))}

                                        </Form.Select>


                                    </Form.Group>
                                </div>
                                <div className='col-md-6'>
                                    <Form.Group className="mb-4" controlId="validationCustom07">
                                        <Form.Label className='custom-lable'>City</Form.Label>
                                        <Form.Group
                                            className="booking-custom-lable"
                                            controlId="validationCustom011"
                                        >
                                            {/* <Form.Select
                                                aria-label="Default select example"
                                                className="custom-select-box"
                                            >
                                                <option value="">Select City</option>
                                                {cities.map((city) => (
                                                    <option value={city} selected={city === userData.city}> {city} </option>
                                                ))}
                                            </Form.Select> */}

                                            <Form.Control required type="text" value={city} name="city" onChange={(e) => setCity(e.target.value)} placeholder="" className='custom-input' />


                                        </Form.Group>
                                        {/* <Form.Control required type="text" value={userData.city} name="city" onChange={(e) => handleChange(e)} placeholder="" className='custom-input' /> */}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <Form.Group className="mb-4" controlId="validationCustom06">
                                        <Form.Label className='custom-lable'>Zip Code</Form.Label>
                                        <Form.Control required type="number" value={userData.zip} name="zip" onChange={(e) => handleChange(e)} placeholder="" className='custom-input' />
                                    </Form.Group>
                                </div>
                                <div className='col-md-6' style={{ display: 'none' }}>
                                    <Form.Group className="mb-4" controlId="validationCustom06">
                                        <Form.Label className='custom-lable'>Zone</Form.Label>
                                        <Form.Control disabled value={userData.zone_name} name="zip" placeholder="" className='custom-input' />
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-12'>
                                    <Form.Group className="mb-4" controlId="validationCustom09">
                                        <Form.Label className='custom-lable'>Special Instructions</Form.Label>
                                        <Form.Control required type="text" value={userData.special_instructions} name="special_instructions" onChange={(e) => handleChange(e)} placeholder="" className='custom-input' />
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='modal-btn'>
                                    <button href="#" className='btn Active-bttn' onClick={updateprofile} >Save Changes</button>
                                    <button href="#" className='btn Cancel-bttn' onClick={handleClose}>Cancel</button>
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
            {/* Edit Profile Modal End Here  */}


            {/* Change Password Modal Start Here */}
            <Modal show={show2} onHide={handleClose2} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custum-modal-body'>
                    <Form noValidate validated={validated} onSubmit={saveChangePassword}>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Form.Group className="mb-4" controlId="validationCustom01">
                                    <Form.Label className='custom-lable'>Old Password</Form.Label>
                                    <Form.Control required type="password" value={oldpassword} onChange={(e) => setOldPassword(e.target.value)} placeholder="**********" className='custom-input' />
                                    {

                                        oldpassword == null && signUprequest > 0 ?
                                            <div class="form-error">Please provide a valid password.</div>

                                            : null
                                    }
                                </Form.Group>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12'>
                                <Form.Group className="mb-4" controlId="validationCustom01">
                                    <Form.Label className='custom-lable'>New Password</Form.Label>
                                    <Form.Control required type="password" value={newpassword} onChange={(e) => setNewPassword(e.target.value)} placeholder="**********" className='custom-input' />
                                    {

                                        newpassword == null && signUprequest > 0 ?
                                            <div class="form-error">Please provide a valid new password.</div>

                                            : null
                                    }
                                </Form.Group>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-12'>
                                <Form.Group className="mb-4" controlId="validationCustom01">
                                    <Form.Label className='custom-lable'>Confirm Password</Form.Label>
                                    <Form.Control required type="password" value={confirmpassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="**********" className='custom-input' />
                                    {

                                        confirmpassword != newpassword && confirmpassword != null ?
                                            <div class="form-error">Password and Confirm password not match.</div>

                                            : null
                                    }
                                </Form.Group>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='modal-btn'>
                                <button href="#" className='btn Active-bttn' onClick={saveChangePassword} >Save Changes</button>
                                <button href="#" className='btn Cancel-bttn' onClick={handleClose2}>Cancel</button>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            {/* Change Password Modal End Here */}

            <div className="App">
                <div className="Profile-card">
                    <div className="Profile-back-cover">
                        <img src={Userback} alt="Rectangle-1" />
                    </div>
                    <div className="Profile-img">
                        {
                            LoggedInData && LoggedInData.image ? (
                                <img
                                    src={`${SITE_URL}uploads/photo/${LoggedInData.image}`}
                                    alt="User"
                                    className="img-fluid rounded-circle"
                                />
                            ) : (
                                <img
                                    src={UserImageProfile}
                                    alt="Default"
                                    className="img-fluid rounded-circle"
                                />

                            )
                        }


                    </div>
                    <div className="Profile-name">
                        <h4>{LoggedInData && `${LoggedInData.first_name} ${LoggedInData.last_name} `}</h4>
                    </div>
                    <div className="profile-btn">
                        <button className="btn" onClick={handleShow}> Edit Profile</button>
                        <button className="btn" onClick={handleShow2}> Change Password</button>
                    </div>
                    <div className="Profile-details">
                        <div className="email">
                            {/* <h6 className='details-width'>Email : </h6>
                            <p>{LoggedInData && LoggedInData.user_email}</p> */}
                            <Form.Group className="mb-3 did-floating-label-content" controlId="formBasicText">
                                <Form.Control className="did-floating-input" type="text" value={LoggedInData && LoggedInData.user_email} readOnly />
                                <Form.Label className="did-floating-label">Email</Form.Label>
                            </Form.Group>
                        </div>
                        <div className="phone">
                            {/* <h6 className='details-width'>Phone : </h6>
                            <p>{LoggedInData && LoggedInData.phone}</p> */}
                            <Form.Group className="mb-3 did-floating-label-content" controlId="formBasicText">
                                <Form.Control className="did-floating-input" type="text" value={LoggedInData && LoggedInData.phone} readOnly />
                                <Form.Label className="did-floating-label">Phone</Form.Label>
                            </Form.Group>
                        </div>
                        <div className="address">
                            {/* <h6 className='details-width'>Zip Code : </h6>
                            <p>{LoggedInData && LoggedInData.zip}</p> */}
                            <Form.Group className="mb-3 did-floating-label-content" controlId="formBasicText">
                                <Form.Control className="did-floating-input" type="text" value={LoggedInData && LoggedInData.zip} readOnly />
                                <Form.Label className="did-floating-label">Zip Code</Form.Label>
                            </Form.Group>
                        </div>
                        <div className="address">
                            {/* <h6 className='details-width'>Address : </h6>
                            <p>{LoggedInData && LoggedInData.address}</p> */}
                            <Form.Group className="mb-3 did-floating-label-content" controlId="formBasicText">
                                <Form.Control className="did-floating-input" type="text" value={LoggedInData && LoggedInData.address} readOnly />
                                <Form.Label className="did-floating-label">Address</Form.Label>
                            </Form.Group>
                        </div>
                        <div className="address ">
                            {/* <h6 className='details-width'>City : </h6>
                            <p>{LoggedInData && LoggedInData.city}</p> */}
                            <Form.Group className="mb-3 did-floating-label-content" controlId="formBasicText">
                                <Form.Control className="did-floating-input" type="text" value={LoggedInData && LoggedInData.city} readOnly />
                                <Form.Label className="did-floating-label">City</Form.Label>
                            </Form.Group>
                        </div>
                        <div className="address">
                            {/* <h6 className='details-width'>State : </h6>
                            <p>{LoggedInData && getstatename(LoggedInData.state)}</p> */}
                            <Form.Group className="mb-3 did-floating-label-content" controlId="formBasicText">
                                <Form.Control className="did-floating-input" type="text" value={LoggedInData && getstatename(LoggedInData.state)} readOnly />
                                <Form.Label className="did-floating-label">State</Form.Label>
                            </Form.Group>
                        </div>
                        <div className="address">
                            {/* <h6 className='details-width'>Preferred Technician : </h6>
                            <p>{LoggedInData && LoggedInData.preferrend_technician}</p> */}
                            <Form.Group className="did-floating-label-content" controlId="formBasicText">
                                <div className="did-floating-textarea">
                                    {LoggedInData && LoggedInData.special_instructions}
                                </div>
                                <Form.Label className="did-floating-label">Special Instructions</Form.Label>
                            </Form.Group>
                        </div>
                        <div className="address" style={{ display: "none" }}>
                            <h6 className='details-width'>Preferred Technician : </h6>
                            <p>{LoggedInData && LoggedInData.preferrend_technician}</p>
                        </div>
                        <div className="address" style={{ display: "none" }}>
                            <h6 className='details-width'>Customer Id : </h6>
                            <p>{LoggedInData && LoggedInData.customer_id}</p>
                        </div>
                        <div className="address" style={{ display: "none" }}>
                            <h6 className='details-width'>Customer Stage: </h6>
                            <p>{LoggedInData && LoggedInData.customer_stage}</p>
                        </div>
                        {/* <div className="address d-flex">
                            <h6 className='details-width'>Technician Notes: </h6>
                            <p>{LoggedInData && LoggedInData.technician_notes}</p>
                        </div> */}

                    </div>
                    <div className="Profile-card-bottom">
                        <div className="profile-bottom-card">
                            {/* <div className="social-images-icon d-flex justify-content-between w-100">
                                <a href='#'><img src={Facebook} alt="Rectangle-1" /> </a>
                                <a href='#'><img src={Nextdoor} alt="Rectangle-1" /> </a>
                                <a href='#'><img src={Yelp} alt="Rectangle-1" /> </a>
                                <a href='#'><img src={Angi} alt="Rectangle-1" /> </a>
                            </div> */}
                            <div className="bottom-discription">
                                <p> Green Frog © {new Date().getFullYear()}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </>
    );
}

export default Profile;


import React from 'react' 
// import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useState } from 'react';
import './mapview.css'
import Sidebar from '../Components/Sidebar/Sidebar'; 
import { Form } from 'react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
import pagination from "./pagination";
import { useEffect } from 'react';
import { getmissingappointment } from '../Api/bookingApi'; 
import moment from "moment";
import { getactivestaff } from "../Api/bookingApi"; 
import Modal from "react-bootstrap/Modal"; 
import Button from "react-bootstrap/Button";
import Spinner from 'react-bootstrap/Spinner';
import { DateRangePicker } from 'react-date-range'; 
import ChildComponent from "../Bookingdata/Appointment"


function Getmissingappointment() {

    const [staffList, setStaffList] = useState(null);
    const [selectedStaff, setSelectedStaff] = useState(0);

    const [changestaffzone, setchangesstaffzone] = useState('');
    const [bookingzonelist, setBookingzonelist] = useState([]);

    const [bookingid, setBookingId] = useState(0);
    const [oldbookingid, setOldBookingId] = useState(0);

    const [loadingsppiner, setLoadingSpinner] = useState(false);

    const today = new Date();
    const [sdate, setSdate] = useState(today);
    const sevenDaysLater = new Date();
    const [edate, setEdate] = useState(sevenDaysLater.setDate(sevenDaysLater.getDate() + 7));

    const [show9, setShow9] = useState(false);

    const handleClose9 = () => setShow9(false);
    const handleShow9 = () => setShow9(true);


    const [dates, setDates] = useState([
        {
            startDate: new Date(),
            endDate: null,
            key: 'selection'
        }
    ]); 

    const getStaffList = () => {
        getactivestaff().then((res) => {
            if (res.code === 200) {
                const apiResponse = res.data;
                setStaffList(apiResponse.data);
            }
        })
            .catch((err) => {
                console.log(err);
            });
    };

    const [staffscoordinates, setStaffsCoordinates] = useState([]);

    useEffect(() => {
        getStaffList();
    }, []);

    const [coordinates, setCoordinates] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const handleSearch = (e) => {
        setSearchValue(e.target.value)
    }

    const coordinatesData = () => {
        setLoadingSpinner(true);
        const startdate = moment(sdate).format("YYYY-MM-DD")
        const endtdate = moment(edate).format("YYYY-MM-DD")
        getmissingappointment(selectedStaff, startdate, endtdate, searchValue, changestaffzone).then((res) => {
            if (res.code === 200) {
                const coordinatsss = res.data;
                setCoordinates(coordinatsss.data);
            }
            else {
                alert("No data found");
            }
        }).catch((err) => {
            console.log("getTechnicianOffTime error");
        }).finally(() => {
            setLoadingSpinner(false);
            handleClose9();
        });
    };

    useEffect(() => {
        coordinatesData();

        const filteredStaff = staffscoordinates.filter(staff => staff.id === selectedStaff);

        if (filteredStaff.length > 0) {
            setStaffsCoordinates(filteredStaff);
        } else {
            setStaffsCoordinates([])
        }

    }, [selectedStaff, searchValue, changestaffzone]);

    const handleButtonClick = () => {
        coordinatesData();
    }

    const callChildFunction = (booking_id) => {
        setBookingId(booking_id);
    };

    const optionFormatter = (cell, row) => {
        return (
            <div className="button-group">
                <Button
                    className="confirm-Active"
                    onClick={
                        () => {
                            callChildFunction(row.order_id);
                        }
                    }
                >
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"></path></svg>
                </Button>
            </div>
        );
    }


    const columns = [
        {
            dataField: "id",
            text: "ID",
            sort: true,
            formatter: (cell, row, rowIndex, extraData) => (
                <div>{rowIndex + 1}</div>
            ),
        },
        {
            dataField: "order_id",
            text: "ORDER ID",
            sort: true,
        },
        {
            dataField: "order_date",
            text: "ORDER DATE",
            isDummy: true,
            sort: true,
        },
        {
            dataField: "booking_date_time",
            text: "BOOKING DATE",
            isDummy: true,
            sort: true,
        },
        {
            dataField: "fullname",
            text: "TECHNICIAN NAME",
            sort: false,
        },
        {
            dataField: "name",
            text: "CLIENT NAME",
            sort: true,
        },
        {
            dataField: "user_email",
            text: "CLIENT EMAIL",
            sort: true,
        },
        {
            dataField: "address",
            text: "ADDRESS",
            sort: true,
            formatter: (cell, row) => (
                <div>
                    {row.address},{row.city},{row.zip},{row.state}
                </div>
            ),
        },
        {
            text: 'ACTION',
            isDummy: true,
            formatter: optionFormatter
        },
    ];

    const formattedDates = sdate || edate ? `${moment(sdate).format('YYYY-MM-DD')} - ${moment(edate).format('YYYY-MM-DD')}` : '';
 

    return (
        <>

            <Modal show={show9} onHide={handleClose9} centered className="modal-center">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Filter data
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="date-picker">
                                <Form.Group
                                    className="booking-custom-lable"
                                    controlId="formBasicEmail"
                                >
                                    <div className="row">
                                        <div className="col-md-12">
                                            <DateRangePicker
                                                onChange={(item) => {
                                                    setSdate(item.selection.startDate);
                                                    setEdate(item.selection.endDate);
                                                    setDates([item.selection]);
                                                }}
                                                showSelectionPreview={true}
                                                moveRangeOnFirstSelection={false}
                                                // months={2}
                                                ranges={dates}
                                                direction="horizontal"
                                            />
                                        </div>
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                        {/* <div className="col-md-4">
                        </div> */}
                        <div className="date-picker">
                            <Form.Group
                                className="booking-custom-lable"
                                controlId="formBasicEmail"
                            >
                                {
                                    loadingsppiner ? (
                                        <Button className="resize_btn btn" disabled>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        </Button>
                                    ) : (
                                        <div className="toggle_btn">
                                            <button className="resize_btn btn" onClick={
                                                () => {
                                                    setCoordinates([]);
                                                    handleButtonClick();
                                                }
                                            }>
                                                <div className="btn-text">
                                                    Submit
                                                </div>
                                            </button>
                                        </div>
                                    )
                                }
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <div className="dashboard-container d-flex">
                <div className="sidebar-container">
                    <Sidebar />
                </div>
                <div className="main-container">
                    <div className="main-page-body-content">
                        {/* <div className="main-page-body-content-header">
                            <Header />
                        </div> */}
                        <div className="main-page-body-content-body">
                            <div className="main-page-body-content-body-container">
                                <div className="container-fulid">
                                    <div className="">
                                        <div className="table-card ">
                                            <div className="card-body table-card-body">
                                                <div className="table-card-header d-flex justify-content-between">
                                                    <h5 className="card-title">Missing Appointment`s</h5>
                                                </div>
                                                <div className="row"> 
                                                </div>
                                                <div className="table-card-body-inner">
                                                    <div className=''>
                                                        <div className='row'>
                                                            <div className='col-lg-12'>
                                                                <div className="animated fadeIn">
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="card">
                                                                                <div className="card-header d-flex table-card-header">
                                                                                    <Form.Group>
                                                                                        <div className="d-flex">
                                                                                            <Form.Control
                                                                                                placeholder='search'
                                                                                                value={searchValue}
                                                                                                onChange={handleSearch.bind(this)}
                                                                                                type="text"
                                                                                            />
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                </div>
                                                                                <div className="card-body">
                                                                                    <BootstrapTable
                                                                                        bootstrap4
                                                                                        keyField="id"
                                                                                        data={coordinates}
                                                                                        columns={columns}
                                                                                        // selectRow={selectRow}
                                                                                        pagination={pagination}
                                                                                        noDataIndication="No data found"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        bookingid != 0 ? (
                            <ChildComponent order_id={bookingid} onCloseidChangebooking={(editbookingid) => setBookingId(editbookingid)} onCloseidChangeoldbookingid={(oldbookingid) => setOldBookingId(oldbookingid)} />
                        ) : (
                            <></>
                        )

                    }
                </div>
            </div>



        </>
    )

}

export default React.memo(Getmissingappointment)